import React, {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { isBrowser } from '@agria/utils';

export interface ExperimentsContextProps {
  experiments: Record<string, any>;
  setExperiments: React.Dispatch<React.SetStateAction<object>>;
}

export const ExperimentsContext = createContext({} as ExperimentsContextProps);
ExperimentsContext.displayName = 'ExperimentsContext';

export const ExperimentsContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [experiments, setExperiments] = useState<object>({
    AGRIA108: process.env.GATSBY_EXPERIMENT_AGRIA108,
  });

  const waitForGlobal = function (key, callback) {
    if (window[key]) {
      callback();
    } else {
      setTimeout(() => {
        waitForGlobal(key, callback);
      }, 100);
    }
  };

  useEffect(() => {
    if (isBrowser) {
      waitForGlobal('experiments', () => {
        setExperiments((current) => ({
          ...current,
          ...window.experiments,
        }));
      });
    }
  }, []);

  const contextValues = useMemo(
    () => ({
      experiments,
      setExperiments,
    }),
    [experiments, setExperiments]
  );

  return (
    <ExperimentsContext.Provider value={contextValues}>
      {children}
    </ExperimentsContext.Provider>
  );
};
