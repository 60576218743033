import { isBrowser } from '@agria/utils';

interface ContentMapping {
  contentGroup?: string;
  contentType?: string;
}

type MappingRule = [RegExp, ContentMapping];

const DEFAULT_MAPPING: ContentMapping = {
  contentGroup: undefined,
  contentType: undefined,
};

const netlifyMappings: MappingRule[] = [
  // brochureware patterns
  [
    /--agria-brochure\.netlify\.app$/,
    {
      contentType: 'brochureware - main',
      contentGroup: 'brochureware pages',
    },
  ],
  [
    /--kc-insurance-brochure\.netlify\.app$/,
    {
      contentType: 'brochureware - main',
      contentGroup: 'brochureware pages',
    },
  ],
  [
    /--lloyds-brochure\.netlify\.app$/,
    {
      contentType: 'brochureware - lloyds',
      contentGroup: 'brochureware pages',
    },
  ],
  [
    /--agria-ireland-brochure\.netlify\.app$/,
    {
      contentType: 'brochureware - ireland',
      contentGroup: 'brochureware pages',
    },
  ],
  // Breeder club patterns
  [
    /--basc-breeders\.netlify\.app$/,
    { contentType: 'breeder club - basc', contentGroup: 'activation journey' },
  ],
  [
    /--join-basc\.netlify\.app$/,
    {
      contentType: 'breeder club - basc join',
      contentGroup: 'activation journey',
    },
  ],
  [
    /--gccf-join-breeder-club\.netlify\.app$/,
    {
      contentType: 'breeder club - gccf join',
      contentGroup: 'activation journey',
    },
  ],
  [
    /--agria-breeder-club\.netlify\.app$/,
    { contentType: 'breeder club - main', contentGroup: 'activation journey' },
  ],
  [
    /--kc-breeder-club\.netlify\.app$/,
    { contentType: 'breeder club - main', contentGroup: 'activation journey' },
  ],
  [
    /--agria-join-breeder-club\.netlify\.app$/,
    {
      contentType: 'breeder club - main join',
      contentGroup: 'activation journey',
    },
  ],
  [
    /--kc-join-breeder-club\.netlify\.app$/,
    {
      contentType: 'breeder club - main join',
      contentGroup: 'activation journey',
    },
  ],
  // Free cover patterns
  [
    /--gccf-breeders\.netlify\.app$/,
    { contentType: 'free cover - gccf', contentGroup: 'activation journey' },
  ],
  [
    /--agria-4wf\.netlify\.app$/,
    { contentType: 'free cover - owners', contentGroup: 'activation journey' },
  ],
  [
    /--kc-owner-activated\.netlify\.app$/,
    { contentType: 'free cover - owners', contentGroup: 'activation journey' },
  ],
  [
    /--agria-rehoming\.netlify\.app$/,
    {
      contentType: 'free cover - rehoming',
      contentGroup: 'activation journey',
    },
  ],
  [
    /--agria-vet-activated\.netlify\.app$/,
    { contentType: 'free cover - vet', contentGroup: 'activation journey' },
  ],
  [
    /--agria-vet-owner-activated\.netlify\.app$/,
    {
      contentType: 'free cover - vet owners',
      contentGroup: 'activation journey',
    },
  ],
  // litter patterns
  [
    /--agria-litter-data-capture\.netlify\.app$/,
    {
      contentType: 'litter data capture - main',
      contentGroup: 'activation journey',
    },
  ],
  [
    /--kc-litter-data-capture\.netlify\.app$/,
    {
      contentType: 'litter data capture - main',
      contentGroup: 'activation journey',
    },
  ],
  // payment patterns
  [
    /--agria-make-a-payment\.netlify\.app$/,
    {
      contentType: 'missed payment - main',
      contentGroup: 'missed payments',
    },
  ],
  [
    /--kc-make-a-payment\.netlify\.app$/,
    {
      contentType: 'missed payment - main',
      contentGroup: 'missed payments',
    },
  ],
  // Claims patterns
  [
    /--agria-customer-online-claims\.netlify\.app$/,
    {
      contentType: 'online claims - customer',
      contentGroup: 'online claims',
    },
  ],
  [
    /--kc-customer-online-claims\.netlify\.app$/,
    {
      contentType: 'online claims - customer',
      contentGroup: 'online claims',
    },
  ],
  [
    /--lloyds-customer-online-claims\.netlify\.app$/,
    {
      contentType: 'online claims - customer',
      contentGroup: 'online claims',
    },
  ],
  [
    /--agria-vet-online-claims\.netlify\.app$/,
    {
      contentType: 'online claims - vet',
      contentGroup: 'online claims',
    },
  ],
  [
    /--kc-vet-online-claims\.netlify\.app$/,
    {
      contentType: 'online claims - vet',
      contentGroup: 'online claims',
    },
  ],
  [
    /--lloyds-vet-online-claims\.netlify\.app$/,
    {
      contentType: 'online claims - vet',
      contentGroup: 'online claims',
    },
  ],
  // Quote and buy patterns
  [
    /--agria-ctm\.netlify\.app$/,
    {
      contentType: 'quote and buy - ctm',
      contentGroup: 'quote and buy journey',
    },
  ],
  [
    /--agria-qab\.netlify\.app$/,
    {
      contentType: 'quote and buy - main',
      contentGroup: 'quote and buy journey',
    },
  ],
  [
    /--kc-qab\.netlify\.app$/,
    {
      contentType: 'quote and buy - main',
      contentGroup: 'quote and buy journey',
    },
  ],
  [
    /--lloyds-qab\.netlify\.app$/,
    {
      contentType: 'quote and buy - main',
      contentGroup: 'quote and buy journey',
    },
  ],
  [
    /--ireland-qab\.netlify\.app$/,
    {
      contentType: 'quote and buy - ireland',
      contentGroup: 'quote and buy journey',
    },
  ],
  [
    /--agria-service-dogs\.netlify\.app$/,
    {
      contentType: 'quote and buy - service dogs',
      contentGroup: 'quote and buy journey',
    },
  ],
  // renewals patterns
  [
    /--agria-ireland-renewals\.netlify\.app$/,
    {
      contentType: 'renewals - ireland',
      contentGroup: 'renewals journey',
    },
  ],
  // transfer cover patterns
  [
    /--agria-transfer-cover\.netlify\.app$/,
    {
      contentType: 'transfer cover - main',
      contentGroup: 'transfer cover',
    },
  ],
  [
    /--kc-transfer-cover\.netlify\.app$/,
    {
      contentType: 'transfer cover - main',
      contentGroup: 'transfer cover',
    },
  ],
];

const productionMappings: MappingRule[] = [
  // brochureware patterns
  [
    /^www\.(agriapet|kcinsurance)\.co\.uk$/,
    {
      contentType: 'brochureware - main',
      contentGroup: 'brochureware pages',
    },
  ],
  [
    /^www\.(agriapet|agriapetinsure)\.ie$/,
    {
      contentType: 'brochureware - ireland',
      contentGroup: 'brochureware pages',
    },
  ],
  // Breeder club patterns
  [
    /^basc-breeders\.(agriapet|kcinsurance)\.co\.uk$/,
    { contentType: 'breeder club - basc', contentGroup: 'activation journey' },
  ],
  [
    /^join-basc\.(agriapet|kcinsurance)\.co\.uk$/,
    {
      contentType: 'breeder club - basc join',
      contentGroup: 'activation journey',
    },
  ],
  [
    /^join-gccf\.(agriapet|kcinsurance)\.co\.uk$/,
    {
      contentType: 'breeder club - gccf join',
      contentGroup: 'activation journey',
    },
  ],
  [
    /^breeders\.(agriapet|kcinsurance)\.co\.uk$/,
    { contentType: 'breeder club - main', contentGroup: 'activation journey' },
  ],
  [
    /^join\.(agriapet|kcinsurance)\.co\.uk$/,
    {
      contentType: 'breeder club - main join',
      contentGroup: 'activation journey',
    },
  ],
  // Free cover patterns
  [
    /^gccf-breeders\.(agriapet|kcinsurance)\.co\.uk$/,
    { contentType: 'free cover - gccf', contentGroup: 'activation journey' },
  ],
  [
    /^owners\.(agriapet|kcinsurance)\.co\.uk$/,
    { contentType: 'free cover - owners', contentGroup: 'activation journey' },
  ],
  [
    /^rehoming\.(agriapet|kcinsurance)\.co\.uk$/,
    {
      contentType: 'free cover - rehoming',
      contentGroup: 'activation journey',
    },
  ],
  [
    /^vets\.(agriapet|kcinsurance)\.co\.uk$/,
    { contentType: 'free cover - vet', contentGroup: 'activation journey' },
  ],
  [
    /^vet-owners\.(agriapet|kcinsurance)\.co\.uk$/,
    {
      contentType: 'free cover - vet owners',
      contentGroup: 'activation journey',
    },
  ],
  // litter patterns
  [
    /^litter-data-capture\.(agriapet|kcinsurance)\.co\.uk$/,
    {
      contentType: 'litter data capture - main',
      contentGroup: 'activation journey',
    },
  ],
  // payment patterns
  [
    /^makeapayment\.(agriapet|kcinsurance)\.co\.uk$/,
    {
      contentType: 'missed payment - main',
      contentGroup: 'missed payments',
    },
  ],
  // Claims patterns
  [
    /^claim\.(agriapet|kcinsurance)\.co\.uk$/,
    {
      contentType: 'online claims - customer',
      contentGroup: 'online claims',
    },
  ],
  [
    /^lloyds-claims\.(agriapet|kcinsurance)\.co\.uk$/,
    {
      contentType: 'online claims - customer',
      contentGroup: 'online claims',
    },
  ],
  [
    /^vetclaim\.(agriapet|kcinsurance)\.co\.uk$/,
    {
      contentType: 'online claims - vet',
      contentGroup: 'online claims',
    },
  ],
  [
    /^lloyds-vetclaims\.(agriapet|kcinsurance)\.co\.uk$/,
    {
      contentType: 'online claims - vet',
      contentGroup: 'online claims',
    },
  ],
  // Quote and buy patterns
  [
    /^ctm\.(agriapet|kcinsurance)\.co\.uk$/,
    {
      contentType: 'quote and buy - ctm',
      contentGroup: 'quote and buy journey',
    },
  ],
  [
    /^quote\.(agriapet|kcinsurance)\.co\.uk$/,
    {
      contentType: 'quote and buy - main',
      contentGroup: 'quote and buy journey',
    },
  ],
  [
    /^quote\.(agriapet|agriapetinsure)\.ie$/,
    {
      contentType: 'quote and buy - ireland',
      contentGroup: 'quote and buy journey',
    },
  ],
  [
    /^service-dogs\.(agriapet|kcinsurance)\.co\.uk$/,
    {
      contentType: 'quote and buy - service dogs',
      contentGroup: 'quote and buy journey',
    },
  ],
  // renewals patterns
  [
    /^renewals\.(agriapet|kcinsurance)\.co\.uk$/,
    {
      contentType: 'renewals - main',
      contentGroup: 'renewals journey',
    },
  ],
  [
    /^renewals\.(agriapet|agriapetinsure)\.ie$/,
    {
      contentType: 'renewals - ireland',
      contentGroup: 'renewals journey',
    },
  ],
  // transfer cover patterns
  [
    /^transfer-cover\.(agriapet|kcinsurance)\.co\.uk$/,
    {
      contentType: 'transfer cover - main',
      contentGroup: 'transfer cover',
    },
  ],
];

export const useContentTypeMapping = () => {
  const getContentTypeMapping = (hostname: string): ContentMapping => {
    if (!hostname) return DEFAULT_MAPPING;

    // Special QAB Netlify mapping
    if (hostname.includes('--agria-qab.netlify.app')) {
      return {
        contentType: 'quote and buy - main',
        contentGroup: 'quote and buy journey',
      };
    }

    // Check Netlify preview URLs
    if (hostname.includes('netlify.app')) {
      const cleanHostname = hostname.split('/')[0];
      const mapping = netlifyMappings.find(([pattern]) =>
        pattern.test(cleanHostname)
      );

      return mapping?.[1] || DEFAULT_MAPPING;
    }

    // Check production URLs
    const mapping = productionMappings.find(([pattern]) =>
      pattern.test(hostname)
    );

    return mapping?.[1] || DEFAULT_MAPPING;
  };

  // If in browser environment, we can provide a convenience method
  const getCurrentContentType = (): ContentMapping =>
    getContentTypeMapping(isBrowser ? window.location.hostname : '');

  return {
    getContentTypeMapping,
    getCurrentContentType,
  };
};

export default useContentTypeMapping;
