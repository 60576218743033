import { ApiResponse, ClaimsApiResponse } from '../types';
import { friendlyErrorMessage } from './handleApiError';
// Some errors don't report as errors, so we need to check for HasErrored
export function dataOrError<T>(data: ApiResponse<T>) {
  if (data.HasErrored) {
    const mapped = friendlyErrorMessage(data.Errors?.[0]?.ErrorCode);
    throw new Error(mapped ?? 'Unknown Error');
  }

  return data.Payload;
}

export function dataOrErrorClaims<T>(data: ClaimsApiResponse<T>) {
  if (data.hasErrored) {
    const mapped = friendlyErrorMessage(data.error!);
    throw new Error(mapped ?? data.error);
  }

  return data.payload;
}
