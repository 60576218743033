/* eslint-disable react/no-danger */
import React from 'react';
import { GatsbyBrowser } from 'gatsby';
import { BaseLayout } from './src/components/BaseLayout';
import { PageProvider } from './src/context/pageContext';
import { BrochurewareDataLayerWrapper } from './src/components/BrochurewareDataLayerWrapper';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  const pageID = props?.data?.page?.remoteId;
  const brand = props?.data?.page?.brand;
  const eventId = props?.data?.page?.eventId;
  const eventLogo = props?.data?.page?.eventLogo;
  const stickyCallToAction = props?.data?.page?.stickyCallToAction;
  const overrideStickyCTAButton = props?.data?.page?.overrideStickyCTAButton;

  //  Minimal layout removes all navigation items from the header/footer
  const useMinimalLayout = !!eventId;

  return (
    <BrochurewareDataLayerWrapper
      data={props.data}
      location={props.location}
      pageContext={props.pageContext}
    >
      <PageProvider
        pageID={pageID}
        stickyCallToAction={stickyCallToAction}
        overrideStickyCTAButton={overrideStickyCTAButton}
      >
        <BaseLayout
          brand={brand}
          location={props.location}
          useMinimalLayout={useMinimalLayout}
          eventLogo={eventLogo}
        >
          {element}
        </BaseLayout>
      </PageProvider>
    </BrochurewareDataLayerWrapper>
  );
};
