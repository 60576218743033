import { getColorTones } from '../../../utils';
import { brandColors } from './brand';

export const systemColors = {
  systemFocus: `0px 0px 0px 2px #FFF, 0px 0px 0px 4px ${brandColors.brandOne}`,
  systemFocusValid: `0px 0px 0px 1px #4E9C33, 0px 0px 0px 3px #FFF, 0px 0px 0px 5px ${brandColors.brandOne};`,
  systemFocusInvalid: `0px 0px 0px 1px #C92C36, 0px 0px 0px 3px #FFF, 0px 0px 0px 5px ${brandColors.brandOne};`,
  systemInformation: brandColors.brandOne,
  systemSuccess: '#4E9C33',
  systemError: '#C92C36',
  systemWarning: '#E66D00',
  ...getColorTones('#C92C36', 'systemError', { only100and10: true }),
  ...getColorTones('#4E9C33', 'systemSuccess', { only100and10: true }),
  ...getColorTones(brandColors.brandOne, 'systemInformation', {
    only100and10: true,
  }),
} as const;
