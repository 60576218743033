import { FC, useEffect, useRef, useCallback } from 'react';
import { PageProps } from 'gatsby';
import { useDataLayerPush } from '@agria/theme/src/hooks/useDataLayerPush';
import useContentTypeMapping from '@agria/theme/src/hooks/useContentTypeMapping';
import { isBrowser } from '@agria/utils';

// ==== Configuration Constants ====
const CONFIG = {
  // Keys for sessionStorage
  LAST_PAGE_KEY: 'agria_last_page',
  PREVIOUS_PAGE_KEY: 'agria_previous_page', // Dedicated key for the previous page (referrer)
  EXTERNAL_REFERRER_KEY: 'agria_external_referrer',
  FIRST_VISIT_KEY: 'agria_first_visit',
  LAST_PAGE_PATH_KEY: 'agria_last_page_path', // New key to track the path separately

  // Configuration for domains to use
  DOMAINS: [
    'agriapet.co.uk',
    'kcinsurance.co.uk',
    'agriapetinsure.ie',
    'agriapet.ie',
    'lloyds-quote.agriapet.co.uk',
  ],
};

// ==== Type Definitions ====
export interface DataLayerWrapperProps {
  children: React.ReactNode;
  data?: PageProps['data'];
  serverData?: PageProps['serverData'];
  location: PageProps['location'];
}

interface PageData {
  event: string;
  spa_page_url: string;
  spa_page_referrer: string;
  content_group?: string;
  content_type?: string;
  [key: string]: any;
}

// ==== Utility Functions ====
/**
 * Safe URL operation wrapper to handle exceptions consistently
 */
const safeURLOperation = <T,>(operation: () => T, fallback: T): T => {
  try {
    return operation();
  } catch (e) {
    console.warn('URL operation failed:', e);
    return fallback;
  }
};

/**
 * Check if a hostname belongs to one of our domains
 */
const isOwnedDomain = (hostname: string): boolean => {
  if (!hostname) return false;

  const lowercaseHostname = hostname.toLowerCase();
  return CONFIG.DOMAINS.some((domain) => lowercaseHostname.includes(domain));
};

/**
 * Check if a URL belongs to one of our domains
 */
const isAgriaDomain = (url: string): boolean =>
  safeURLOperation(() => {
    const { hostname } = new URL(url);
    return isOwnedDomain(hostname);
  }, false);

/**
 * Extract referrer from URL parameters
 */
const getReferrerFromUrlParams = (): string | null => {
  if (!isBrowser) return null;

  return safeURLOperation(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const refSrc = urlParams.get('ref_src');
    return refSrc ? decodeURIComponent(refSrc) : null;
  }, null);
};

/**
 * Clean up URL by removing referrer parameter
 */
const cleanUpUrl = (): void => {
  if (!isBrowser) return;

  safeURLOperation(() => {
    const url = new URL(window.location.href);

    if (url.searchParams.has('ref_src')) {
      url.searchParams.delete('ref_src');
      window.history.replaceState({}, document.title, url.toString());
    }
  }, undefined);
};

/**
 * Creates a clean version of a URL without ref_src parameter but preserving the hash
 */
const createCleanUrl = (dirtyUrl: string): string =>
  safeURLOperation(() => {
    const url = new URL(dirtyUrl);
    url.searchParams.delete('ref_src');
    // Make sure to preserve the hash
    return url.toString();
  }, dirtyUrl);

/**
 * Process a single link for cross-domain referrer
 */
const processLink = (
  link: HTMLAnchorElement,
  currentOrigin: string,
  currentCleanUrl: string
): void => {
  // Skip invalid links
  if (
    !link.href ||
    link.href === '#' ||
    link.href.startsWith('javascript:') ||
    link.href.startsWith('tel:') ||
    link.href.startsWith('mailto:')
  ) {
    return;
  }

  // Only process links to our domains
  const linkHostname = safeURLOperation(() => link.hostname.toLowerCase(), '');
  if (!isOwnedDomain(linkHostname)) {
    return;
  }

  // Skip if same origin
  const linkOrigin = safeURLOperation(() => new URL(link.href).origin, '');
  if (linkOrigin === currentOrigin) {
    return;
  }

  // Process the link - it's going to a different subdomain
  safeURLOperation(() => {
    const url = new URL(link.href);
    url.searchParams.set('ref_src', encodeURIComponent(currentCleanUrl));
    link.href = url.toString();
  }, undefined);
};

// ==== Main Component ====
export const DataLayerWrapper: FC<DataLayerWrapperProps> = ({
  children,
  location,
}) => {
  const { dataLayerPush } = useDataLayerPush();
  const { getContentTypeMapping } = useContentTypeMapping();

  // Refs for tracking state
  const hasInitialised = useRef<boolean>(false);
  const pageReferrerRef = useRef<string | null>(null);
  const previousPathRef = useRef<string | null>(null);
  const previousHashRef = useRef<string | null>(null);
  const isFirstPageRef = useRef<boolean>(true);
  const observerRef = useRef<MutationObserver | null>(null);
  const lastFullUrlRef = useRef<string | null>(null);

  // Process all existing links on the page
  const processExistingLinks = useCallback(
    (currentOrigin: string, currentCleanUrl: string) => {
      if (!isBrowser) return;

      // First clean any previously processed links
      document.querySelectorAll('a').forEach((link) => {
        safeURLOperation(() => {
          if (link.href && link.href.includes('ref_src=')) {
            const url = new URL(link.href);
            url.searchParams.delete('ref_src');
            link.href = url.toString();
          }
        }, undefined);
      });

      // Then process all links
      document.querySelectorAll('a').forEach((link) => {
        processLink(link, currentOrigin, currentCleanUrl);
      });
    },
    []
  );

  // Set up MutationObserver to handle dynamically added links
  const setupLinkObserver = useCallback(
    (currentOrigin: string, currentCleanUrl: string) => {
      if (!isBrowser) return null;

      // Clean up previous observer if it exists
      if (observerRef.current) {
        observerRef.current.disconnect();
      }

      // Create new observer
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node.nodeName === 'A') {
              processLink(
                node as HTMLAnchorElement,
                currentOrigin,
                currentCleanUrl
              );
            } else if ((node as Element).querySelectorAll) {
              (node as Element).querySelectorAll('a').forEach((link) => {
                processLink(link, currentOrigin, currentCleanUrl);
              });
            }
          });
        });
      });

      observer.observe(document.body, { childList: true, subtree: true });
      return observer;
    },
    []
  );

  // Initialise on first render
  useEffect(() => {
    if (isBrowser) {
      // Check if this is a returning visitor
      isFirstPageRef.current =
        sessionStorage.getItem(CONFIG.FIRST_VISIT_KEY) !== 'true';

      // Load previous path from sessionStorage (if any)
      previousPathRef.current = sessionStorage.getItem(
        CONFIG.LAST_PAGE_PATH_KEY
      );

      // Load last complete URL (with hash) from sessionStorage
      lastFullUrlRef.current = sessionStorage.getItem(CONFIG.LAST_PAGE_KEY);
    }

    // Cleanup function
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  // Handle location changes
  useEffect(() => {
    if (!location?.host || !isBrowser) return;

    // Get content type mapping
    const { contentGroup, contentType } = getContentTypeMapping(location.host);

    // Get the current URL and create a clean version for analytics
    const currentUrl = location.href;
    const currentOrigin = window.location.origin;
    const currentCleanUrl = createCleanUrl(currentUrl);
    const currentPath = location.pathname;
    const currentHash = location.hash || '';
    const currentFullPath = currentPath + currentHash;

    // Check if only the hash has changed but path remains the same
    const isHashChangeOnly =
      hasInitialised.current &&
      currentPath === previousPathRef.current &&
      currentHash !== previousHashRef.current;

    // Get the cross-subdomain referrer parameter
    const urlParamReferrer = getReferrerFromUrlParams();

    // Get the native document.referrer
    const nativeReferrer = document.referrer;

    // Get previous page from sessionStorage (our dedicated referrer storage)
    const previousPageFromStorage = sessionStorage.getItem(
      CONFIG.PREVIOUS_PAGE_KEY
    );

    // Get current page that's stored (will become previous after this navigation)
    const lastPageFromStorage = sessionStorage.getItem(CONFIG.LAST_PAGE_KEY);

    // Determine pageReferrer based on improved rules
    let pageReferrer: string | null = null;

    // Rule 1: Use URL param for cross-subdomain tracking
    if (urlParamReferrer) {
      pageReferrer = urlParamReferrer;
      // Clear this from storage once used
      cleanUpUrl();
    }
    // Rule 2: Use document.referrer for the first page view
    else if (isFirstPageRef.current) {
      pageReferrer = nativeReferrer || null;

      // Mark first visit complete
      sessionStorage.setItem(CONFIG.FIRST_VISIT_KEY, 'true');
      isFirstPageRef.current = false;

      // Remember external referrer
      if (pageReferrer && !isAgriaDomain(pageReferrer)) {
        sessionStorage.setItem(CONFIG.EXTERNAL_REFERRER_KEY, pageReferrer);
      }
    }
    // Rule 3: For hash changes, use the full current URL (with path and without new hash)
    else if (isHashChangeOnly && lastFullUrlRef.current) {
      pageReferrer = lastFullUrlRef.current;
    }
    // Rule 4: Use previous page for internal navigation
    else {
      // Step 1: Try dedicated previous page storage first (most reliable)
      if (previousPageFromStorage) {
        pageReferrer = previousPageFromStorage;
      }
      // Step 2: Fall back to the React ref if available
      else if (pageReferrerRef.current) {
        pageReferrer = pageReferrerRef.current;
      }
      // Step 3: Try the last page storage (might work for the first navigation)
      else if (lastPageFromStorage) {
        pageReferrer = lastPageFromStorage;
      }
      // Step 4: Last resort, use native referrer
      else {
        pageReferrer = nativeReferrer;
      }
    }

    // Don't set the referrer if it's the same as the current URL
    if (pageReferrer === currentUrl) {
      pageReferrer = '';
    }

    // Push to dataLayer
    const pageData: PageData = {
      event: 'virtual_pageview',
      spa_page_url: currentCleanUrl,
      spa_page_referrer: pageReferrer || '',
    };

    // Add optional content fields if available
    if (contentGroup) pageData.content_group = contentGroup;
    if (contentType) pageData.content_type = contentType;

    // Push to data layer
    dataLayerPush(pageData);

    // Before updating current page, store it as the previous page for next navigation
    sessionStorage.setItem(CONFIG.PREVIOUS_PAGE_KEY, currentCleanUrl);

    // Update storage and refs for next navigation
    pageReferrerRef.current = currentCleanUrl;
    previousPathRef.current = currentPath;
    previousHashRef.current = currentHash;
    lastFullUrlRef.current = currentCleanUrl;

    // Always store current page in sessionStorage for resilience
    sessionStorage.setItem(CONFIG.LAST_PAGE_KEY, currentCleanUrl);
    sessionStorage.setItem(CONFIG.LAST_PAGE_PATH_KEY, currentFullPath);

    // Process existing links
    processExistingLinks(currentOrigin, currentCleanUrl);

    // Setup observer for dynamically added links
    observerRef.current = setupLinkObserver(currentOrigin, currentCleanUrl);

    // Mark as initialised
    hasInitialised.current = true;
  }, [
    location,
    location.hash,
    dataLayerPush,
    getContentTypeMapping,
    processExistingLinks,
    setupLinkObserver,
  ]);

  return children;
};
